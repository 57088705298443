'use client';

import { NewsCardMTCounters } from '@/Features/MT/Counters';
import style from './news.module.sass';
import { useState, useEffect } from 'react';
import { useAppSelector } from '@/Hooks/redux';
import Image from 'next/image';
import Link from 'next/link';
import { NewsCardMTButtons } from '@/Features/MT/Buttons';
import { NewsCardMTButtonsCard } from '@/Features/MT/ButtonsCard';
import NewsText from './TextRedactor';
import { ArticlesMTCounters } from '@/Features/MT/StoryIDCounters';
import { OpenTeaser } from '@/Hooks/useTiser';

export const NewsItem = ({ el, inputRef }: { el: any; inputRef: any }) => {
	const { newsViewBollean } = useAppSelector(state => state.NewsViewSlice);
	const { CurrentFontSize } = useAppSelector(state => state.HeaderState);
	const [isHovered, setHovered] = useState({ isHover: false, id: NaN });
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		// Check on mount
		handleResize();

		// Add event listener
		window.addEventListener('resize', handleResize);

		// Cleanup
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const handleOptionsClick = () => {
		setHovered(prev =>
			prev.isHover && prev.id === el.id
				? { isHover: false, id: NaN }
				: { isHover: true, id: el.id }
		);
	};

	const handleMouseEnter = () => {
		if (!isMobile) {
			setHovered({ isHover: true, id: el.id });
		}
	};

	const handleMouseLeave = () => {
		if (!isMobile) {
			setHovered({ isHover: false, id: el.id });
		}
	};

	return (
		<>
			{newsViewBollean == false ? (
				<section
					key={el.id}
					className={style.Wrapper}
					ref={inputRef}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
				>
					<div className={style.cardFlexWrap}>
						<div className={style.logoNameCont}>
							<Link
								href={`/publisher/${el.publisher_id}`}
								className={style.logoNameCont_Logo}
							>
								<Image
									width={16}
									height={16}
									src={el.publisher_logo_url}
									alt='logo'
								/>
							</Link>
							<h3
								className={style.mainTxt}
								style={{
									fontSize: `${16 + CurrentFontSize}px`
								}}
							>
								<NewsText
									StoryId={el.story_id}
									text={el.title}
									url={el.url}
									Id={el.id}
									PublisherId={el.publisher_id}
									el={el}
								/>
							</h3>
						</div>
						<div className={style.ContofOptions}>
							<div
								className={
									isHovered.isHover === true && isHovered.id === el.id
										? style.optionsCont
										: style.optionsContClose
								}
							>
								<a
									className={style.LinkComment}
									target={window.ReactNativeWebView ? '_self' : '_blank'}
									onClick={e => {
										OpenTeaser(el);
										if (window.ReactNativeWebView) {
											e.preventDefault();
										}
									}}
									href={
										el.hasOwnProperty('fulltext_properties')
											? `/article/${el.id}/full`
											: el.url
									}
								>
									<div className={style.commentBtn}>
										<svg
											width='15'
											height='15'
											viewBox='0 0 17 17'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M10 1H16M16 1V7M16 1L8 9'
												stroke='#33363F'
												stroke-width='1.5'
											/>
											<path
												d='M7 2H3C1.89543 2 1 2.89543 1 4V14C1 15.1046 1.89543 16 3 16H13C14.1046 16 15 15.1046 15 14V10'
												stroke='#33363F'
												stroke-width='2'
												stroke-linecap='round'
											/>
										</svg>
										Читать
									</div>
								</a>
							</div>
							<div className={style.HotBtnCont}>
								<NewsCardMTButtons
									StoryId={el.story_id}
									isHovered={
										isHovered.isHover === true && isHovered.id === el.id
									}
								/>
							</div>
						</div>
					</div>
					<a
						// className={
						// 	isHovered.isHover === true && isHovered.id === el.id
						// 		? style.openOptionsCont
						// 		: style.openOptionsContFalse
						// }
						className={style.openOptionsContFalse}
						target='_blank'
						href={
							el.hasOwnProperty('fulltext_properties')
								? `/article/${el.id}/full`
								: el.url
						}
						// onClick={handleOptionsClick}
					>
						{/* <svg
							width='16'
							height='16'
							viewBox='0 0 16 4'
							fill='#858585'
							xmlns='http://www.w3.org/2000/svg'
						>
							<circle cx='1.75' cy='1.75' r='1.75'></circle>
							<circle cx='7.875' cy='1.75' r='1.75'></circle>
							<circle cx='14' cy='1.75' r='1.75'></circle>
						</svg> */}
						<svg
							width='17'
							height='17'
							viewBox='0 0 17 17'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M10 1H16M16 1V7M16 1L8 9'
								stroke='#858585'
								stroke-width='2'
							/>
							<path
								d='M7 2H3C1.89543 2 1 2.89543 1 4V14C1 15.1046 1.89543 16 3 16H13C14.1046 16 15 15.1046 15 14V10'
								stroke='#858585'
								stroke-width='2'
								stroke-linecap='round'
							/>
						</svg>
					</a>
				</section>
			) : (
				<section ref={inputRef} key={el.id} className={style.CardWrapper}>
					{el.image == null ? (
						<div className={style.NewsPreview}></div>
					) : (
						// <Image
						// 	className={style.NewsPreview}
						// 	src={el.image}
						// 	alt='logo'
						// 	width={280}
						// 	height={200}
						// 	display
						// />
						<Image
							className={style.NewsPreview}
							src={el.image}
							alt='logo'
							width={280}
							height={200}
						/>
					)}

					<div className={style.NewsCardName}>
						<Image
							width={16}
							height={16}
							src={el.publisher_logo_url}
							alt='logo'
						/>
						<h4
							className={style.NewsName}
							style={{
								fontSize: `${14 + CurrentFontSize}px`
							}}
						>
							{el.publisher_name}
						</h4>
						<ArticlesMTCounters StoryId={el.story_id} />
					</div>
					<div className={style.logoNameContCard}>
						<h3
							className={style.mainCardTxt}
							style={{
								fontSize: `${19 + CurrentFontSize}px`
							}}
						>
							<a
								className={style.Links}
								target={window.ReactNativeWebView ? '_self' : '_blank'}
								href={`/article/${el.id}/story`}
							>
								{el.title}
							</a>
						</h3>
					</div>
					<NewsCardMTButtonsCard el={el} StoryId={el.story_id} Id={el.id} />
				</section>
			)}
		</>
	);
};
