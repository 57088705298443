'use client';

import style from '@/Features/NewsComponents/news.module.sass';
import React, { useEffect, useRef } from 'react';
import Audio from '../AudioSlider/audio';
import Quiz from '@/Widgets/QuizSlider/quiz';
import Video from '@/Widgets/VideoSlider/video';
import Quote from '@/Widgets/QuoteSlider/quote';
import { NewsItem } from '@/Features/NewsComponents/NewsItem';
import { NewNews } from '@/Features/NewsComponents/NewNews';
import Script from 'next/script';

export default function News({ elAll, inputRef }: any) {
	// получеие данных redux

	const ArrLoading = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

	// (function () {
	// 	var sc = document.createElement('script');
	// 	sc.type = 'text/javascript';
	// 	sc.async = true;
	// 	sc.src = '//smi2.ru/data/js/97260.js';
	// 	sc.charset = 'utf-8';
	// 	var s: any = document.getElementsByTagName('script')[0];
	// 	s.parentNode.insertBefore(sc, s);
	// })();
	// (function () {
	// 	var sc = document.createElement('script');
	// 	sc.type = 'text/javascript';
	// 	sc.async = true;
	// 	sc.src = '//smi2.ru/data/js/97261.js';
	// 	sc.charset = 'utf-8';
	// 	var s: any = document.getElementsByTagName('script')[0];
	// 	s.parentNode.insertBefore(sc, s);
	// })();
	return (
		<>
			{elAll?.data.map((el: any, ind: any, allArr: any) => {
				return (
					<React.Fragment key={ind}>
						{ind === 16 && (
							<>
								<div className={style.ZenCont}>
									<Script async src={'//smi2.ru/data/js/97260.js'}></Script>
									<div id='unit_97260'></div>
								</div>

								<div className={style.ZenContMobile}>
									<Script async src={'//smi2.ru/data/js/97261.js'}></Script>
									<div id='unit_97261'></div>
								</div>
							</>
						)}
						{ind === 45 && (
							<div className={style.VideoCont}>
								<div className={style.adfoxCnt}>
									<div className={style.part}>У ПАРТНЕРОВ СМИ2</div>
									<div id='adfox_159067343332936426'></div>
								</div>
							</div>
						)}
						{/* {ind === 10 && (
							<div className={style.QuizCont}>
								<Audio />
							</div>
						)} */}
						{ind === 25 && (
							<div className={style.QuizCont}>
								<Quiz />
							</div>
						)}
						{ind === 7 && (
							<div className={style.VideoCont}>
								<Video />
							</div>
						)}
						{ind === 36 && (
							<div className={style.QuoteCont}>
								<Quote />
							</div>
						)}
						<NewsItem
							el={el}
							inputRef={
								allArr.length > 40 && allArr.length - 15 === ind
									? inputRef
									: null
							}
						/>
					</React.Fragment>
				);
			})}
			{elAll?.Loading &&
				ArrLoading.map(el => (
					<section className={style.LoadingWrapper} key={el}>
						<div className={style.cardFlexWrap}>
							<div className={style.logoNameCont}>
								<div className={style.LoadingLogo}></div>
							</div>
						</div>
						<div className={style.LoadingText}></div>
					</section>
				))}
		</>
	);
}
