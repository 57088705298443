import style from '@/Features/NewsComponents/news.module.sass';
import React, { useRef, useState, useEffect } from 'react';
import Link from 'next/link';

interface NewsCardMTButtonsCardProps {
	StoryId: number;
	Id: number;
	boxColor?: any;
	el: any;
}

interface ReactionData {
	like: number;
	dislike: number;
	userReaction: 'like' | 'dislike' | null;
}

export function NewsCardMTButtonsCard({
	StoryId,
	Id,
	boxColor,
	el
}: NewsCardMTButtonsCardProps) {
	const ArticlesStoryId = StoryId;

	const id = ArticlesStoryId !== undefined ? `story-${ArticlesStoryId}` : false;
	const type = 'smi2';

	const likeRef = useRef<boolean | null>(null);
	const likesCountRef = useRef<number>(0);
	const dislikesCountRef = useRef<number>(0);
	const [renderTrigger, setRenderTrigger] = useState<number>(0);

	useEffect(() => {
		const callbackFn = ({
			reactions: { like, dislike },
			userReaction
		}: {
			reactions: { like: number; dislike: number };
			userReaction: 'like' | 'dislike' | null;
		}) => {
			likesCountRef.current = like;
			dislikesCountRef.current = dislike;
			likeRef.current = userReaction === 'like';
			setRenderTrigger(rt => rt + 1);
		};
		if (id) {
			window.mtMessenger.push([
				'subscribeChatExternalReactions',
				{
					id,
					type,
					callbackFn
				}
			]);
		}

		return () => {
			window.mtMessenger.push([
				'unsubscribeChatExternalReactions',
				{ id, type }
			]);
		};
	}, [id, type]);

	const handleLikeClick = () => {
		const reactionName = likeRef.current ? null : 'like';
		window.mtMessenger.push([
			'setChatExternalReaction',
			{ id, type, reactionName }
		]);
	};

	return (
		<div className={style.optionsCardCont}>
			<a
				className={style.Links}
				target='_blank'
				href={
					el.hasOwnProperty('fulltext_properties')
						? `/article/${el.id}/full`
						: el.url
				}
			>
				<div className={style.commentBtnCard}>
					<svg
						width='15'
						height='15'
						viewBox='0 0 17 17'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M10 1H16M16 1V7M16 1L8 9'
							stroke='#33363F'
							stroke-width='1.5'
						/>
						<path
							d='M7 2H3C1.89543 2 1 2.89543 1 4V14C1 15.1046 1.89543 16 3 16H13C14.1046 16 15 15.1046 15 14V10'
							stroke='#33363F'
							stroke-width='2'
							stroke-linecap='round'
						/>
					</svg>
					Читать
				</div>
			</a>
			<div
				className={style.hotNewsBtnCard}
				onClick={handleLikeClick}
				style={likeRef.current ? { background: '$OptionsButton-color' } : {}}
			>
				<svg
					className={likeRef.current ? style.GreenBtn : style.DefaultBtn}
					width='20'
					height='20'
					viewBox='0 0 20 20'
					fill={likeRef.current ? '#45AD4E' : ''}
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M13 4H8L6 10H9L7 17L14 8H10L13 4Z'
						stroke-width='2'
						stroke-linejoin='round'
					></path>
				</svg>
			</div>
		</div>
	);
}
